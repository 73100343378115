	header {
  	background-image: url('../img/hero.jpg');
  	background-repeat: no-repeat;
  	background-size: 100%;
  	background-size: cover;
  	padding-bottom: 5%;
  	background-position: center;

	@media ( max-width:1195px ) {
		padding-bottom: 8%;
	}

	@media ( max-width:616px ) {
		padding-bottom: 12%;
	}

    }
  	header#produkt {
    	min-height: 82px;
    	max-height: 82px;
    	height: 82px;
    	padding-bottom: 0;

    	h1, p, .button {
    		display: none;
    	}

  

    	.logo {
    		padding: 1%;
    		border:none;
 	    
 	    @media ( max-width:726px ) {
			padding: 1.5%;
		}


    	}
    }

    .title {
    	width: 30%;

	    @media ( max-width:726px ) {
			width: 80%;
		}

	    @media ( max-width:524px ) {
			width: 90%;
		}
    }
	

  	p {
  		color:#fff;
  		font-family: $secondary-font;
  		font-size: 1.9em;
  		padding-left:6%;
  		margin-bottom: 53px;

  		@media ( max-width:617px ) {
				font-size: 1.8em;
			}
  	}

  	h1 {
		color: #fff;
	  	text-transform: uppercase;
	  	font-family: $primary-font;
	  	font-size: 8.3em;
	  	padding: 6%;
	  	margin-top: 3%;
	  	margin-bottom:0;
	  	padding-bottom:0;
	  	line-height: 1.2;
	  	
	  	@media ( max-width:1368px ) {
			font-size: 7em;
			}
	  	@media ( max-width:1240px ) {
			font-size: 6em;
			}
	  	@media ( max-width:1080px ) {
			font-size: 5em;
			}
	  	@media ( max-width:504px ) {
			font-size: 3em;
			margin-top: 9%;
			}
		}



	.logo {
	  	padding:1%;
	  	position:relative;
	  	border:none !important;
	  	z-index: 99;
	  	float:left;
	  	top:0px;

	  	@media ( max-width:1146px ) {
			width: 37%;
		}

	  	@media ( max-width:1013px ) {
			width: 33%;
			padding: 1.5%;
		}

	  	@media ( max-width:916px ) {
			width: 379px;
		}

	  	@media ( max-width:621px ) {
			padding: 3.5%;
			width: 66%;
		}

		img {

		@media ( max-width:1146px ) {
			width: 100%;
		}
		}

	}

	nav {
		right: 0;
		z-index: 1;
		font-family: $primary-font;
		position: fixed;
		text-align: right;
		padding: 1%;
		width: 100%;
		background-color: rgba(0, 0, 0, 0.6);
		height: 55px;
		display: block;
		padding: 0.5% 2% 1.5% 0;

	  	ul {
	  		list-style: none;

	  		li {
	  			display: inline-block;
	  			font-size: 1.6em;
	  			padding:13px 1% 13px 1%;

				.active {
				  	border-top: 7px solid rgb(47, 96, 163);
				  	padding-top: 25px !important;

				  	&:hover {
				  		border:none;
				  	}	
				 }


	  				@media ( max-width:1368px ) {
						font-size: 1.5em;
						padding:13px 1% 13px 2%;
					}
	  				@media ( max-width:1240px ) {
						font-size: 1.5em;
						padding:13px 1% 13px 1%;
					}
	  				@media ( max-width:1080px ) {
						font-size: 1.4em;
						padding:13px 1% 13px 0.5%;
					}

	  			a {
	  				color:#fff;
	  				text-decoration: none;
	  				text-transform: uppercase;
	  				letter-spacing: 0.5px;
					-webkit-transition: border-top 0.2s ease-out;
				    -moz-transition: border-top 0.2s ease-out;
				    -o-transition: border-top 0.2s ease-out;
				    transition: border-top 0.2s ease-out;

	  				&:hover {
	  					border-top: 7px solid rgb(47, 96, 163);
	  					padding-top: 25px !important;
	  					overflow: hidden;	 
	  				}

				}//a
	  		}//li
		}//ul
	}//nav	
	

	#produkt nav {
    	min-height: 70px;
    	max-height: 70px;
    	height: 70px;
		padding: 6px 1%;
	}
#menu-toogle {
	display: none;
}

@media ( max-width:916px ) {

	.menu {
		position:relative;
	}
	#menu-toogle {
		display:block;
		position: fixed;
		top: 0px;
		right: 15px;
		z-index: 999;
	}

	nav {
		display: none;
		padding-right: 0;
		width: 100%;
		height: 100%;
		top: 0;
		background-color: rgba(0, 0, 0, 0.6);
		

		ul {
			padding-left: 0;
			padding-top: 8%;

			li {
				display: block;
				text-align: center;
				padding: 3%;

				.active {
				  	border-top: none;
				  	padding-top: 0px !important;
				}	

				a {
					font-size: 31.5px;

					&:hover {
				  		border:none;
				  	}
				}
			}
		}	
	}	
	#toogle-nav-open {
		position: absolute;
		top: 0;
		color: white;
		font-size: 36px;
		z-index: 99;
		right: 0;
		padding:1.5%;

		.fa {
			padding: 22%;
			background-color: rgba(0, 0, 0, 0.6);
		}
	}
	#toogle-nav-close {
		display: none;
		position: absolute;
		top: 0;
		color: white;
		font-size: 36px;
		z-index: 99;
		right: 0;
		padding:1.5%;

		.fa {
			padding: 22%;
			
		}
	}	


	.active { 
		border-top: none;
	}
}


	.button {
	  color: #fff;
		padding: 19px;
		border: 1px solid $primary-color;
		text-transform: uppercase;
		text-decoration: none;
		background-color: #000;
		font-family: $primary-font;
		background-color: rgba(0, 0, 0, 0.6);
		position:relative;
		left: 6%;
		font-size: 1.5em;
		letter-spacing: 0.5px;
		-webkit-transition: background-color 0.2s ease-out;
		-moz-transition: background-color 0.2s ease-out;
		-o-transition: background-color 0.2s ease-out;
		transition: background-color 0.2s ease-out;

		&:hover {
			background-color: rgba(0, 0, 0, 0.9);
		}
	}
