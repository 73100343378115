#product {
	min-height: 300px;
	width: 100%;
	display: block;
	position: relative;
	h2 {
		margin-bottom: 0;
	}
}
.sliderWrapper {
	width: 100%;
	margin: 0 auto;
	display: block;
	position: relative;

	.hoverInfo {
		position: absolute;
		text-align: center;
		background: rgba(0, 0, 0, 0);
		width:100%;
		height: 100%;
		h3 {
			font-family: $primary-font;
			text-transform: uppercase;
			font-size:5em;
			color:  rgba(0, 0, 0, 0);
			padding-bottom: 0;
			margin-bottom: 0;
		}
		p {
			font-family: $secondary-font;
			font-size:2em;
			color:  rgba(0, 0, 0, 0);
			padding-bottom: 1em;
		}
		a {
			font-family: $primary-font;
			text-transform: uppercase;
			font-size:1.6em;
			opacity:0;
			letter-spacing: 0.5px;
			border: 1px solid #fff;
			background-color: rgba(0, 0, 0, 0.50);
			color: #fff;
			text-decoration: none;
			padding: 1em;
			height:2em;
			position: relative;
			top: 50%;
		}
		&.activeSlide {
			font-size: inherit;
			display: block;
			background: rgba(0, 0, 0, 0.5);
			filter:alpha(opacity=50);/*position where      
			enlarged image should offset horizontally */
			-webkit-transition: all 0.4s ease-in-out;
			-moz-transition: all 0.4s ease-in-out;
			-o-transition: all 0.4s ease-in-out;
			transition: all 0.4s ease-in-out;
			h3 {
				color: rgba(255, 255, 255, 0.98);
			}
			p {
				color: rgba(255, 255, 255, 0.98);
			}
			a {
				opacity:1;
				&:hover {
					color: #ffffff;
					background-color: rgba(0, 0, 0, 0.80);
				}
			}
		}&.activeSlideMobile {
			text-align: left;
			font-size: inherit;
			display: block;
			background: rgba(0, 0, 0, 0);
			filter:alpha(opacity=50);/*position where      
			enlarged image should offset horizontally */
			-webkit-transition: all 0.4s ease-in-out;
			-moz-transition: all 0.4s ease-in-out;
			-o-transition: all 0.4s ease-in-out;
			transition: all 0.4s ease-in-out;
			h3 {
				color: rgba(255, 255, 255, 0.98);
			}
			p {
				color: rgba(255, 255, 255, 0.98);
			}
			a {
				position: absolute;
				opacity:1;
				top: 5%;
				left:5%;
				padding: 0.8em;
				height:1.5em;

				&:hover {
					color: #ffffff;
					background-color: rgba(0, 0, 0, 0.80);
				}
			}
		}
		
	}
	.desktop {
		display:block;
		@media ( max-width:1024px ) {
			display: none;
		}
	}
	.mobile {
		display: none;
		@media ( max-width:1024px ) {
			display: block;
		}
	}
	.royalSlider {
		width: 100%;
		height: auto;
		//max-width: 1920px;
		min-height: 800px;
		margin: 0 auto;
		position: relative;
		@media ( max-height:768px ) and (orientation: portrait) {
			min-height: 600px;
			max-height: 800px;
			.sliderWrapper .hoverInfo {
			font-size: inherit;
			display: block;
			background: rgba(0, 0, 0, 0);
			filter:alpha(opacity=50);/*position where      
			enlarged image should offset horizontally */

				a {
					opacity:1;
						color: #ffffff;
						background-color: rgba(0, 0, 0, 0.80);
				}
			}
		}
		@media ( max-width:320px ) and (orientation: portrait) {
			min-height: 300px;
			max-height: 400px;
			.sliderWrapper .hoverInfo {
			font-size: inherit;
			display: block;
			background: rgba(0, 0, 0, 0.5);
			filter:alpha(opacity=50);/*position where      
			enlarged image should offset horizontally */

				a {
					opacity:1;
						color: #ffffff;
						background-color: rgba(0, 0, 0, 0.80);
				}
			}
		}
		@media ( max-width:568px ) and (orientation: landscape) {
			min-height: 300px;
			max-height: 400px;
			.sliderWrapper .hoverInfo {
			font-size: inherit;
			display: block;
			background: rgba(0, 0, 0, 0.5);
			filter:alpha(opacity=50);/*position where      
			enlarged image should offset horizontally */

				a {
					opacity:1;
						color: #ffffff;
						background-color: rgba(0, 0, 0, 0.80);
				}
			}
		}

	
	}
	.leftWrapper {
		width:66.7%;
		float: left;
		display: block;
		position: relative;
	
	}
	.rightWrapper {
		width:33.3%;
		float: right;
		display: block;
		position: relative;
	
	}
	.topWrapper {
		height: 50%;
		min-height: 400px;
		display: block;
		position: relative;
	
	}
	#productsSlider .imageHolder, #productsSliderMobile .imageHolder {
		position: absolute;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
	}
	#productsSliderMobile .imageHolder {
		width: 100%;
		height: 100%;
		top:0;
		left:0;
	}
	#productsSlider .img1 {
		width: 50%;
		height: 100%;
		top:0;
		left:0;
	}
	#productsSlider .img2 {
		width: 50%;
		height: 100%;
		top:0;
		right:0;
	}
	#productsSlider .img3 {
		width: 100%;
		height: 100%;

	}
	#productsSlider .img4 {
		position: relative;
		min-height: 800px;
		width: 100%;
		height: auto;
	}
	
}

	.rsDefault .rsArrow {
		height: 20px !important;	
	}
	.rsDefault.rsHor .rsArrowLeft {
		left:inherit !important;
		top:inherit !important;
		right: 65px !important;
		bottom: 20px !important;
		@media ( max-width:768px ) {
			bottom: 50px !important;
		}
	}
	.rsDefault.rsHor .rsArrowRight {
		left:inherit !important;
		top:inherit !important;
		right: 0 !important;
		bottom: 20px !important;
		@media ( max-width:768px ) {
			bottom: 50px !important;
		}
	}
	.rsDefault.rsHor .rsArrowIcn {
		background-color: $primary-color !important;
		opacity: 0.8 !important;
	}
	.rsDefault .rsTabs {
		height: auto !important;
		box-sizing: border-box;
  		padding-left: 2%;
		text-align: left;
		overflow: hidden;
		top: -50px;
	}
	.rsDefault .rsTab {
	border: none;
	border-right: 1px solid #f5f5f5;
	background-color: $tertiary-color;
  	margin: 0 11px 0 0; 
}
	
.rsDefault .rsTab:last-child { 
	border-right:  1px solid #cfcfcf;
}
.rsDefault .rsTab:active { 
	border: none;   
	background-color: $primary-color;
	box-shadow:  none;
}
.rsDefault .rsTab.rsNavSelected { 
	color: #FFF;
	border: none;
	text-shadow: 1px 1px #838383;
	box-shadow: none;
	background: $primary-color;
}

.rsDefault .rsTab:last-child{
	border-radius: 0 !important;
}

.rsDefault .rsTab:first-child{
	border-radius: 0 !important;
}

.rsDefault.rsHor .rsArrowLeft .rsArrowIcn {
	background-color: $black !important;
	opacity: 0.9 !important;
}

.rsDefault.rsHor .rsArrowIcn {
	background-color: $black !important;
	opacity: 0.9 !important;
}


#produktUnderside .royalSlider, #produktUnderside .royalSlider .rsOverflow {
		max-height: 810px !important;
	}