$primary-color: #2F60A3; //Blue
$secondary-color: #313030; // Black
$tertiary-color: #e7e7e7; //gray
$black: #252424;

$primary-font: 'Oswald', sans-serif;
$secondary-font: 'Muli', sans-serif;



body { 
	background-image: url('../img/pattern-2.png');
	margin:0;
	padding:0;
	font-size: 10px; /* INITIAL FONTSIZE = 1em */
}

.pattern {
	background-image: url('../img/pattern.png');
	width: 100%;
	padding-bottom: 102px;

  @media ( max-width:533px ) {
		padding-bottom:0;
	}
}

h2 {
	background-color:  $primary-color;
	padding:33px;
	padding-left: 4%;
	font-family: $primary-font;
	font-size: 4em;
	text-transform: uppercase;
	color: #fff;
	margin-top:0;
	text-align: left;
		

	@media ( max-width:533px ) {
		text-align:center;
		margin-bottom: 0;
		font-size: 3em;
	}
}

h3 {
	font-size:2.6em;
	font-family: $secondary-font;
	font-weight: 500;
} 

h4 {
	font-family: $primary-font;
	text-transform: uppercase;
	font-size: 2.2em;
	text-align:left;
}
  
h6 {
	font-family: $primary-font;
	font-size: 3em;
	text-align:center;
	margin-bottom:40px;

	@media ( max-width:664px ) {
	  margin: 7% auto;
	  width: 90%;
	}

	@media ( max-width:329px ) {
		  font-size: 2.6em;
		  width: 81%;
		  margin: 9% auto;
	}
}

p {
	font-size:1.7em;
	font-family: $secondary-font;
	line-height: 1.4;

}

//------------------- Header - ------------------

@import "./partials/_header.scss";


//------------------- Om Kennedy -------------------


.about-kennedy {
	overflow: hidden;
	position: relative;
	text-align: center;
	width: 100%;
	display: block;
	
	}

.wrapper-about {
	position: relative; 
	width: 82%;
	max-width: 1000px;
	margin: 0 auto;

	p {
		color: #000;
		text-align: justify;
		padding-left: 0;
		font-size: 1.7em;
	}

		@media ( max-width:533px ) {
			width: 100% !important;

		}

	.img {
		background-image: url('../img/aboutKennedy.jpg');
		background-size: cover;
		background-repeat: no-repeat;
		display: block;
		position: relative;
		float:left;
		background-position: center right;
		padding: 3.01%;

		@media ( max-width:790px ) {
			width: 91% !important;
			box-sizing: border-box;
			background-position: top right;
			max-height: 382px;
			float: none;
			margin: 0 auto;
		}

		@media ( max-width:533px ){
			width: 100% !important;
		}
	}

	.about {
		background-color: #fff;
		text-align: left;
		width: 41%;
		padding: 5.01%;
		padding-top: 4%;
  		padding-bottom: 2%;
		display: block;
		position: relative;
		float: left;

		@media ( max-width:790px ) {
			float: none;
			width:91%;
			box-sizing: border-box;
			margin: 0 auto;
		}

		@media ( max-width:533px ){
			width: 100% !important;
		}
	}
}

//------------------- Møt våre ansatte -------------------

.entry {
	margin: 0 auto;
	width: 98%;
	margin-left: 2%;
	margin-right: 2%;
	text-align: center;

	p {
		padding-left: 0;
	}

  @media ( max-width:1100px ) {
		width: 96%;
	}

	.employees {
		position: relative;
		margin: 0 .5% 20px .5%;
		display: inline-block;
		min-width:25%;

		@media ( max-width:1385px ) {
				width: 31%;
			}

			@media ( max-width:1100px ) {
				width: 47%;
			}

		@media ( max-width:738px ) {
				width: 99%;
			}

		img {

		width:100%;

		@media ( max-width:738px ) {
				width: 100%;
			}
	}
  }


	.title-bg {
	background-color: $primary-color;
	padding: 5%;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		color: #fff;

		@media ( max-width:364px ) {
			padding: 14px;
			padding-left: 9%;
		}
	}


	h5 {
		margin-bottom: 0;
		font-family: $primary-font;
		text-transform: uppercase;
		font-size:22px;
		margin-top: 0;
		text-align: left;
		letter-spacing: 0.5px;

		@media ( max-width:333px ) {
			font-size: 19px;
		}
	}

	p {
		font-family: $secondary-font;
		margin-top:4px;
		font-size:17px;
		margin-bottom: 0;
		text-align: left;

		@media ( max-width:333px ) {
			font-size: 17px;
		}
	}
}

	.space {

		 @media ( max-width: 1420px ) {
				display: inline-block;
			}


	}

//------------------- Tjenester -------------------

.offers {
		margin-bottom: 6%;

		p {
			color: #000;
			padding-left: 0;
		}

	.offers-text {
		  width: 52%;
			margin-top: 10px;
			font-size: 1.5em;
			margin-bottom: 6%;

			@media ( max-width:1447px ) {
				width:64%;
			}

			@media ( max-width:1092px ) {
				width:78%;
			}

			@media ( max-width:769px ) {
				width:88%;
			}
	}



		

	.offerOuterWrapper {
	width: 85%;
	margin: 0 auto;
	text-align:center;


	@media ( max-width:1666px ) {
			width: 96%;
		}
	}

	.WeOffer {
	background-color: $tertiary-color;
	width: 307px;
	display:inline-block;
	margin: 0px .5% 20px .5%;
	padding: 43px;
	text-align:center;

	@media ( max-width:435px ) {
			width: 96%;
			padding: 0px !important;
			padding-top: 29px !important;
			padding-bottom: 29px !important;
		}

	&.padding-one {
		padding-top: 51px;
		padding-bottom: 56px;
	}

	&.padding-two {
	  padding-top: 54px;
		padding-bottom: 56px;
	}

	&.padding-three {
		padding-top: 75px;
		padding-bottom: 56px;
	}

	&.padding-three{
		padding-bottom: 55px;
		padding-top: 56px;
	}

		p {
			width: 80%;
				margin-top: 10px;
				font-size: 1.5em;
			}

			h5 {
				margin: 0 auto;

				@media ( max-width:329px ) {
					font-size: 19px
				}
			}

		.innerWeOffer {
			position: relative;
			padding-bottom: 12px;
			padding-top: 13px;
		}
	}

	img {
		border-radius: 50%;
		border: 2px #000 dashed;
		-webkit-border-radius: 50%; 
		-moz-border-radius: 50%;
		-o-border-radius: 50%;
		-ms-border-radius: 50%;

		width:126px;
		margin-bottom: 20px;

	}
	
	h5 {
		margin-bottom: 0;
		font-family: $primary-font;
		text-transform: uppercase;
		font-size:20px;
		margin-top: 13px;
		text-align: center;
	}

	p {
		text-align: center;
	margin: 0 auto;
	}
	
}

//------------------- Produkter -------------------

@import "./partials/_produkter.scss";

//------------------- Referanser -------------------

.reference {
	overflow: hidden;
	position: relative;
	left: 0;
	clear: both;
	display: block;
	top: -27px;

	p {
		font-size: 1.5em;
		color: #000;
		padding-left: 0;
		text-align: justify;
		margin-bottom: 13px;

		@media ( max-width:1280px ) {
			margin-bottom: 26px;
		}

		@media ( max-width:533px ) {
		  width: 89%;
		  margin: 5% auto;
		}
	}

	.wrapper-reference {
		position: relative; 
		width: 1251px;
		margin: 0 auto;
		
		@media ( max-width:1280px ) {
			width: 80%;
		}

		@media ( max-width:533px ) {
			width: 100%;
		}

		.text-bg {
			background-color: $black;
			padding: 2%;
			color: #ffffff;
			margin-bottom: 0;

			@media ( max-width:533px ) {
				padding: 5%;
			}

		  p {
			width: 74%;
			margin: 0 auto;
			text-align: center;
			padding: 1%;
			color: #fff;

			@media ( max-width:533px ) {
				width:96%;
			}
		  }
		}

		.img {
			background-size: cover;
			background-repeat: no-repeat;
			display: block;
			position: relative;
			float:left;
			background-position: center center;
			padding: 5%;
			width: 40%; /*DENNE FJERNES NÅR DET SKAL VÆRE TO REFERANSER */

			@media ( max-width:1280px ) {
				min-height: 420px;
				width: 90%;
			}

		}

		.ref-img-one {
			background-image: url('../img/referanse-1.jpg');
		}

		.ref-img-two {
			background-image: url('../img/referanse-2.jpg');
		}

		.reference-one {
			background-color: #fff;
			text-align: left;
			width: 50%;
			width: 500px;
			padding: 5%;
			display: block;
			position: relative;
			float: left;

			@media ( max-width:1280px ) {
					width: 90%;
					float: left;
			}

			@media ( max-width:533px ) {
				  padding-top: 11%;
				  padding-bottom: 15%;
			}
		}

		.reference-two {
			background-color: #fff;
			text-align: left;
			width: 50%;
			width: 500px;
			padding: 5%;
			display: block;
			position: relative;
			float: right;

			@media ( max-width:1280px ) {
				width: 90%;
				float: left;
			}

			@media ( max-width:533px ) {
				  padding-top: 11%;
				  padding-bottom: 15%;
			}
		}

		.reference-one, .reference-two {

		  h3 {
				text-transform: uppercase;
				font-size: 40px;
				margin-top: 0;
				margin-bottom: 0;
				text-align:left;
				font-weight: 800;
				font-family: $primary-font;

				@media ( max-width:533px ) {
				  text-align: center;
				  font-size: 37px;
				}
			}

			.name {
				font-family: $primary-font;
				font-size: 17px;
				font-weight: 800;
				margin-bottom:0;
			}
		}	
	}
}


//------------------- Samarbeidspartnere -------------------
#providers {
	
	p {
		width: 52%;
		margin: 0 auto;
		text-align:center;
		margin-top: 5%;
		margin-bottom: 6%;
		font-size: 1.5em;
		color: #000;
		padding-left: 0;

			@media ( max-width:1447px ) {
				width:64%;
			}

			@media ( max-width:1092px ) {
				width:78%;
			}

			@media ( max-width:769px ) {
				width:88%;
			}
	}

	

	img {
		margin-bottom: 1.5%;
	}

	.wrapper-company {
	  width: 95%;
		margin: 0 auto;
		margin-bottom: 3%;
		text-align: center;

		@media ( max-width:490px ) {
			margin-bottom: 8%;
		}

		.bergeneholm {
			width: 10%;
			border:none;

			@media ( max-width:850px ) {
				width:17%;
			}

			@media ( max-width:490px ) {
				width:26%;
			}
		}

	.egilcarlsen {
			width: 11%;
			border:none;

			@media ( max-width:850px ) {
				width:17%;
			}

			@media ( max-width:490px ) {
				width:26%;
			}
		}

	.fjerdingstad {
			width: 12%;
			border:none;

			@media ( max-width:850px ) {
				width:21%;
			}

			@media ( max-width:490px ) {
				width:32%;
			}
		}

	.isomur {
			width: 8%;
			border:none;

			@media ( max-width:850px ) {
				width:13%;
			}

			@media ( max-width:490px ) {
				width:20%;
			}
		}

	.icopal {
		border:none;

		@media ( max-width:850px ) {
			width:6%;
		}

		@media ( max-width:490px ) {
			width:9%;
		}
	}

	.jatak {
			width: 7%;
			border:none;

			@media ( max-width:850px ) {
				width:12%;
			}

			@media ( max-width:490px ) {
				width:18%;
			}
		}

	.gravedrift {
			width: 8%;
			border:none;

			@media ( max-width:850px ) {
				width:16%;
			}

			@media ( max-width:490px ) {
				width:22%;
			}
		}

	.sodra {
			width: 10%;
			border:none;

			@media ( max-width:850px ) {
				width:17%;
			}

			@media ( max-width:490px ) {
				width:24%;
			}
		}

		.trappespesialisten {
			width: 13%;
			border:none;

			@media ( max-width:850px ) {
				width:22%;
			}

			@media ( max-width:490px ) {
				width:36%;
			}
		}

		.elektriske {
			width: 12%;
			border:none;

			@media ( max-width:850px ) {
				width:20%;
			}

			@media ( max-width:490px ) {
				width:32%;
			}
		}

		.ferder {
			width: 6%;
			border:none;

			@media ( max-width:850px ) {
				width:10%;
			}

			@media ( max-width:490px ) {
				width:16%;
			}
		}

		.glava {
			width: 6%;
			border:none;

			@media ( max-width:850px ) {
				width:12%;
			}

			@media ( max-width:490px ) {
				width:18%;
			}
		}

		.langved {
			width: 7%;
			border:none;

			@media ( max-width:850px ) {
				width:13%;
			}

			@media ( max-width:490px ) {
				width:20%;
			}
		}

		.maxbo {
			width: 9%;
			border:none;

			@media ( max-width:850px ) {
				width:15%;
			}

			@media ( max-width:490px ) {
				width:22%;
			}
		}

		.nymoeneriksen {
			width: 8%;
			border:none;

			@media ( max-width:850px ) {
				width:16%;
			}

			@media ( max-width:490px ) {
				width:26%;
			}
		}

		.wienerberger {
			width: 9%;
			border:none;

			@media ( max-width:850px ) {
				width:16%;
			}

			@media ( max-width:490px ) {
				width:22%;
			}
		}

		.koramic {
			width: 10%;
			border:none;

			@media ( max-width:850px ) {
				width:15%;
			}

			@media ( max-width:490px ) {
				width:23%;
			}
		}


		.company {
		opacity: 0.7;
		padding: 1%;
		text-decoration: none;
		}
	}
}

//------------------- Kontakt oss/ Footer -------------------

/*.produktSide.outerFooter {
	top: -30px;
	position: relative;
}*/
		
.outerFooter {
	
	h2 {
		margin-bottom: 0;
	}
	
}
/*.produktSideFooter {
	top: -30px;
	position: relative;
}*/


footer {
	background-image: url('../img/pattern.png');
	width: 100%;
	padding-bottom: 102px;
	height:174px;

	.phone {
		font-size: 1.45em !important;
	}

	.innerFooter {
		padding:2%;
		float:left;
		width: 50%;
		padding-top: 2%;
		margin: 3%;
		margin-top: 0;
		margin-bottom: 0;
		padding-bottom: 0;

		@media ( max-width:1119px ) {
				padding-top: 5%;
			}

			@media ( max-width:639px ) {
				width: 93%;
				padding-top: 8%;
			padding-bottom: 4%;
			}
	}

	h3 {
		font-family: $primary-font;
		text-transform: uppercase;
		color: #fff;	
		margin-top: 0;

		@media ( max-width:639px ) {
				float: left;
				width: 100%;
			}
	}

	p {
		color: #fff;
		margin-bottom: 0;
		margin-top: 0;
		padding-left: 0;
		font-size: 1.7em;
		font-family:  $secondary-font;
	}

	span {
		color: #fff;
		margin-bottom: 0;
		margin-top: 0;
		font-family:  $secondary-font;
		font-size: 19px;
		font-size: 1.45em;
		line-height: 1.5;
	}

	a {
		color: #fff;
		font-family:  $secondary-font;
		font-size: 1.45em;
		line-height: 1.5;
	}

	h4 {
		color: #fff;
		margin-top: 0;
		margin-bottom: 8px;
	}

	.outerbox {
		width: 35%;
		float: right;

		@media ( max-width:639px ) {
					float: left;
					width: 100%;
				}
		
		.box {
			background-color: $primary-color;
			padding: 30px;
			padding-right: 82px;
			float: right;
			margin-right: 4%;

			@media ( max-width:898px ) {
				padding: 16%;
				  padding-bottom: 12%;
				  padding-top: 12%;					
				}

				@media ( max-width:639px ) {
					float: none;
					margin-right: 0;
					padding: 5%;
					padding-top: 8%;
				padding-bottom: 0%;
				}

				h5 {
					font-family: $primary-font;
					font-size: 1.9em;
					text-transform: uppercase;
					color: #ffffff;
					margin-bottom: 9px;
				font-weight: 100;
				margin-top: 0;
				}
			}
	  }
}

.copyright {
	float: right;
	padding-right: 2%;
	padding-top: 1.3%;

	@media ( max-width:645px ) {
	background-color: rgb(47, 96, 163);
  width: 100%;
  padding-top: 0;

	}

	 img { 
		width: 91px;
		padding: 5%;
		float: right;
		border:none;

		@media ( max-width:645px ) {
		padding-top:0;
		opacity: 0.5;
	}

	@media ( max-width:368px ) {
		float: left;
		padding-top: 2%;
	}
	 }
}

	#map {
	  height:350px;
	  width:100%;
	  right: 0;

	/*  @media ( max-width:1000px ) {
				height: 419px;
			}*/
	}

	.gm-style .gm-style-iw {
		  width: 76% !important;
		  left: 19px !important;
	}

	.gm-style-iw * {
		display: block;
		width: 100% !important;
  		font-size: 17px;
  		overflow: hidden !important;
	}
	.gm-style-iw h4, .gm-style-iw p {
		margin: 0;
		padding: 0;
		text-align: center;
	}
	.gm-style-iw a {
		color: #4272db;
	}